/* Check if the user is authenticated */

import { LiteUser } from "../../models/user";
import axiosRequest from "../utils/axiosRequest";

// Return a boolean true/false.
export const authorizeUser = async (otpToken: string): Promise<{ isAuth: boolean; user?: LiteUser }> => {

  const response = await axiosRequest({
    method: "POST",
    data: {
      fn: "checkUserAuthToken",
      otpToken: otpToken,
    },
  });

  // Token failed backend validation
  if (response.data.message !== "Authorized") {
    // Remove invalid token from local storage and don't authenticate
    localStorage.removeItem("otp_token");
    return { isAuth: false };
  }

  // Token passed backend validation
  return { isAuth: true, user: response.data.response.user };
};
