import axios from "axios";

interface AxiosConfig {
  method?: string;
  url: string;
  //   maxBodyLength: number;
  headers?: { Authorization?: string; "Content-Type"?: string };
  params?: object;
  data?: object;
}

export interface UseAxiosProps {
  method?: "GET" | "POST" | "PUT";
  // headers?: { Authorization?: string; "Content-Type"?: string };
  endpoint?: string; // Endpoint to append to base URL
  url?: string; // Full URL
  params?: object;
  data?: any;
}

// Resuable call to internal and external APIs
const axiosRequest = async ({
  method = "GET", // Default: get
  endpoint = "/portalRequests", // API endpoint
  url,
  params,
  data,
}: // contentType,
// responseType,
UseAxiosProps) => {
  // Base config
  let config = { headers: {} } as AxiosConfig;

  // Pick url based on hostname
  const baseUrl =
    window.location.hostname === "aime.rootwise.co"
      ? import.meta.env.VITE_API_ENDPOINT_PROD + endpoint
      : import.meta.env.VITE_API_ENDPOINT_DEV + endpoint;

  // Axios config options: AMP API
  config = {
    ...config,
    method: method,
    url: url || baseUrl || "",
    params: params,
    data: data,
    //   responseType: "json",
  };

  if (config.params) {
    console.log(`${endpoint} params:`, JSON.stringify(config.params, null, 2));
  }
  if (config.data) {
    console.log(`${endpoint} data:`, JSON.stringify(config.data, null, 2));
  }

  const response = await axios.request(config);
  return response;
};

export default axiosRequest;
