import { ReactNode, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

// Local
import theme from "./lib/theme/default";
import Login from "./lib/auth/Login";
import { CircleLoader } from "./components/navigation/Loading";
import { LiteUser } from "./models/user";
import { authorizeUser } from "./lib/auth/handleAuthToken";
import { UserContext } from "./lib/contexts";

interface AppProps {
  children: ReactNode;
}

/* App is a wrapper component for auth, context, layout, and design */

function App({ children }: AppProps) {
  /* Authentication */
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // State
  const [user, setUser] = useState<LiteUser | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("otp_token"); // Check for auth token
      if (token && token !== "undefined") {
        const { isAuth, user } = await authorizeUser(token); // Check if token valid
        if (isAuth) {
          // If token is validated by AIME backend, set user and authentication status
          setUser(user || null);
          setIsAuthenticated(true);
        }
      }
      setIsCheckingAuth(false);
    };
    checkAuth();
  }, []);

  useEffect(() => {
    console.log("User: ", user);
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={user}>
        <Box bgcolor="background.default" height="100vh" width="100vw" m={0}>
          {isCheckingAuth ? ( // While checking authentication, show a loading screen
            <CircleLoader />
          ) : isAuthenticated ? ( // If authenticated, show the app
            children
          ) : (
            // If not authenticated, show the login screen
            <Login setIsAuthenticated={setIsAuthenticated} setUser={setUser} />
          )}
        </Box>
      </UserContext.Provider>
    </ThemeProvider>
  );
}

export default App;
