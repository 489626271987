// MUI\
import { Box, Fab, Tooltip, Typography, Link } from "@mui/material";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";

// Local
import { useEffect, useCallback } from "react";
import axiosRequest from "../utils/axiosRequest";
import { LiteUser } from "../../models/user";
import useDrawer from "../../hooks/useDrawer";

interface Props {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setUser: (user: LiteUser | null) => void;
}

// Authenticate with OTPless
function Login({ setIsAuthenticated, setUser }: Props) {
  // Drawer for login instructions
  const { AimeDrawer, setIsDrawerOpen } = useDrawer();

  const handleOtplessAuth = useCallback(
    async (otpless: { token: string; identities: [{ identityValue: string }] }) => {
      try {
        const response = await axiosRequest({
          method: "POST",
          data: {
            fn: "setNewAuthToken",
            otpToken: otpless.token,
            userId: otpless.identities[0].identityValue,
          },
        });

        const { user } = response.data.response;
        if (user) {
          localStorage.setItem("otp_token", otpless.token);
          setIsAuthenticated(true);
          setUser(user);
        }
      } catch (error) {
        console.error("Authentication failed:", error);
      }
    },
    [setIsAuthenticated, setUser]
  );

  useEffect(() => {
    (window as any).otpless = handleOtplessAuth;

    return () => {
      delete (window as any).otpless;
    };
  }, [handleOtplessAuth]);

  // Load OTPless SDK
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "otpless-sdk";
    script.type = "text/javascript";
    script.src = "https://otpless.com/v2/auth.js";
    script.setAttribute("data-appid", import.meta.env.VITE_OTPLESS_APP_ID);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box display="flex" height="100vh" alignItems="center" justifyContent="center">
      <div id="otpless-login-page" />

      <Tooltip title="Need help logging in?" placement="top">
        <Fab
          onClick={() => setIsDrawerOpen(true)}
          color="primary"
          aria-label="add"
          sx={{ position: "absolute", bottom: 16, right: 16 }}
        >
          <QuestionMarkOutlinedIcon fontSize="large" />
        </Fab>
      </Tooltip>

      {/* Drawer to explain user login experience */}
      <AimeDrawer>
        <Typography variant="h2">Login Instructions</Typography>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h5" paragraph>
            First time users, follow these steps to log in:
          </Typography>
          <ol>
            <Typography component="li" variant="body1" sx={{ mb: 1 }}>
              Click the "Continue with WhatsApp" button.
            </Typography>
            <Typography component="li" variant="body1" sx={{ mb: 1 }}>
              Scan the QR code with your phone's camera.
            </Typography>
            <Typography component="li" variant="body1" sx={{ mb: 1 }}>
              Send the default message in WhatsApp (it will be pre-filled for you).
            </Typography>
            <Typography component="li" variant="body1">
              Click the approve button to complete the login process.
            </Typography>
          </ol>
          <Typography variant="h5" paragraph sx={{ mt: 4 }}>
            Returning users have fewer steps:
          </Typography>
          <ol>
            <Typography component="li" variant="body1" sx={{ mb: 1 }}>
              Click the "Continue with WhatsApp" button.
            </Typography>
            <Typography component="li" variant="body1" sx={{ mb: 1 }}>
              Select your number from the list provided.
            </Typography>
          </ol>
          <Typography variant="h5" paragraph sx={{ mt: 4 }}>
            If you have any issues or would like to request access, please contact RootWise:
          </Typography>
          <Typography variant="body1" sx={{ display: "flex", justifyContent: "center" }}>
            <Link
              href="https://www.rootwise.co/contact-us/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ display: "inline-block", mt: 1 }}
            >
              https://www.rootwise.co/contact-us/
            </Link>
          </Typography>
        </Box>
      </AimeDrawer>
    </Box>
  );
}

export default Login;

/*

Flow --> everywhere!
1. check if otp token
2. if otp token, send to back-end for validation
3. if valid, a) put in context, b) redirect to dashboard
4. if invalid, send to login

*/
