import { lazy } from "react";
import { createRootRoute, Outlet } from '@tanstack/react-router'
import App from '../App'

const TanStackRouterDevtools = 
    import.meta.env.PROD
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      )

export const Route = createRootRoute({
  component: () => (
    <App>
      <Outlet />
      <TanStackRouterDevtools position="bottom-right" />
    </App>
  ),
})