import { Box, CircularProgress, LinearProgress } from "@mui/material";

export const CircleLoader = () => {
  return (
    <Box display="flex" justifyContent="center" my={4}>
      <CircularProgress />
    </Box>
  );
};


export const LinearLoader = () => {
  return <LinearProgress color="primary" />;
};