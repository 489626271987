// Global
import { useState, ReactNode } from "react";

// MUI
import { Box, Drawer, useMediaQuery, useTheme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const useDrawer = () => {
  // Global hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const AimeDrawer = ({ children }: { children: ReactNode }) => {
    return (
      <Drawer
        anchor={isSmallScreen ? "bottom" : "right"}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: isSmallScreen ? "auto" : "500px",
            height: isSmallScreen ? "80vh" : 1,
          },
        }}
      >
        {/* Close button */}
        <Box
          onClick={() => setIsDrawerOpen(false)}
          zIndex={1}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
          }}
        >
          <ClearIcon />{" "}
        </Box>
        <Box my={4} mx={2}>
        {children}
        </Box>
      </Drawer>
    );
  };

  return { AimeDrawer, isDrawerOpen, setIsDrawerOpen };
};

export default useDrawer;
